import React from 'react'
import styled from 'styled-components'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3rem;
`
const Index = () => (
  <Layout>
    <SEO title="Home" />
    <Wrapper>
      <a
        href="https://www.westelm.com/products/mindfulness-framed-wall-art-by-minted-for-west-elm-w4809/"
        target="_blank"
        rel="noreferrer noopener"
        title="Click to visit store page"
      >
        <StaticImage
          src="../images/hero.jpg"
          alt="painting"
          style={{ maxHeight: '100vh', maxWidth: '100%' }}
          objectFit="contain"
        />
      </a>
    </Wrapper>
  </Layout>
)

export default Index
